<template>
  <page-layout ref="layout">
    <template #actions="{ }">
      <can do="create" on="management-instruments">
        <b-button v-b-tooltip="'Create'" v-b-modal="'instrument-modal'" variant="transparent" size="sm">
          <font-awesome-icon icon="fa-solid fa-plus" />
        </b-button>
        <instrument-modal id="instrument-modal" @created="addInstrument"></instrument-modal>
      </can>
    </template>

    <template #dropdown-options="{ }">
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <table-layout ref="table-layout"
                    :items="table.items"
                    :fields="table.fields"
                    :filters="table.filters" :filters-options="{ visible: true, collapsed: true }"
                    :sorting="table.sorting"
                    :loading="table.loading"
                    :subscriptions="table.subscriptions"
                    :is-searchable="true"
                    :func-delete="deleteInstrument"
                    :export-exclude-fields="[
                        'id',
                        'slug',
                        'form.id',
                        'formInstrumentsId',
                        'state.enabled',
                    ]"
                    @mounted="table = $event"
                    @updated="table = $event">

        <template #overlay>
          <overlay-loading :items="[
            { state: table.loading, desc: 'Loading Instruments', loaded: table.items.length},
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
        </template>

        <!-- Filters -->
        <template #filters>
          <b-row>
            <b-col>
              <b-form-group label="Form" label-for="form-input">
                <v-select id="form-input"
                          v-model="table.filters.form.value"
                          :options="filterFormOptions"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100"/>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Enabled" label-for="enabled-input">
                <v-select id="enabled-input"
                          v-model="table.filters.enabled.value"
                          :options="['true', 'false']"
                          :searchable="false"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100"/>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <!-- Column: Name -->
        <template #cell(name)="{data}">
          <b-media vertical-align="center" no-body>
            <b-media-aside>
              <b-avatar v-if="hasCustomAvatar(data.item.avatar)" v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <b-img fluid fluid-grow :src="data.item.avatar.src" :alt="data.item.avatar.name"></b-img>
              </b-avatar>
              <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <font-awesome-icon :icon="icon"></font-awesome-icon>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              <b-link :to="{ name: 'management-instrument', params: { id: data.item.id, slug: data.item.slug } }" class="font-weight-bold d-block text-nowrap">
                {{ data.item.name }}
              </b-link>
            </b-media-body>
          </b-media>
        </template>

        <!-- Column: State - Enabled -->
        <template #cell(state.enabled)="{data}">
          <b-badge pill :variant="`light-${data.item.state.enabled ? 'success' : 'danger'}`" class="text-capitalize">
            {{ data.item.state.enabled }}
          </b-badge>
        </template>

        <template #cell(form.name)="{data}">
          <div v-if="data.item.form">
            {{ data.item.form.name }}
          </div>
          <div v-else class="text-danger">
            No Form
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(row-options)="{data}">
          <b-dropdown-item :to="{ name: 'management-instrument', params: { id: data.item.id, slug: data.item.slug } }"
                           class="table-row-option-view">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>

          <template v-if="$can('update', 'management-instruments') || $can('delete', 'management-instruments')">
            <b-dropdown-divider />
            <can do="update" on="management-instruments">
              <b-dropdown-item class="table-row-option-enable"
                               @click="updateInstrument(data.item)">
                <font-awesome-icon :icon="['fas', data.item.state.enabled ? 'toggle-off' : 'toggle-on']"/>
                <span class="align-middle ml-50">{{ data.item.state.enabled ? 'Disable' : 'Enable' }}</span>
              </b-dropdown-item>
            </can>
            <can do="delete" on="management-instruments">
              <b-dropdown-item class="table-row-option-delete"
                               @click="$refs.layout.confirmDelete(data.item, deleteInstrument, cascadeConfirmDeleteOptions)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </can>
          </template>
        </template>
      </table-layout>
    </template>

    <template #debug>
      <debug :collapsed="true">{{ table }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import InstrumentModal from '@/views/management/instruments/InstrumentModal.vue'
import avatar from '@/mixins/avatar.mixin';
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import vSelect from 'vue-select'
import { API, graphqlOperation } from 'aws-amplify';
import {
  listApplicationsForUpdate,
  listEnsembleInstrumentsForDeletion,
  listInstruments,
  updateApplication,
  updateInstrument,
  onCreateInstrument, onUpdateInstrument, onDeleteInstrument
} from '@/graphql/queries/instruments';
import { cascadeDeleteInstrument, cascadeConfirmDeleteOptions} from '@/graphql/cascade/instrument';

export default {
  name: 'Instruments',
  components: {
    PageLayout,
    TableLayout,
    OverlayLoading,
    InstrumentModal,
    vSelect
  },
  mixins: [ avatar, notify, print ],
  data() {
    return {
      table: {
        items: [],
        loading: true,
        fields: [
          {
            key: 'name',
            label: 'Name',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'order',
            label: 'Order',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'form.name',
            label: 'Form',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'state.enabled',
            label: 'Enabled',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        filters: {
          form: { key: 'form.name', value: null },
          enabled: { key: 'state.enabled', value: null }
        },
        sorting: {
          by: 'name',
          desc: false
        },
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        }
      },
      icon: 'fas fa-music',
      debug: {},
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    filterFormOptions() {
      return [...new Set(this.table.items.map(item => item.form?.name || null).filter(name => name !== null))].sort((a, b) => a.localeCompare(b))
    },
    filterEnabledOptions() {
      return [{ label: 'true', value: true }, { label: 'false', value: 'false' }]
    },
  },
  async created() {
    await this.listInstruments();
    this.onCreateInstrument();
    this.onUpdateInstrument();
    this.onDeleteInstrument();
  },
  mounted() {
    this.$refs.layout.state.loading = false
  },
  methods: {
    /** Table Data **/
    async listInstruments() {
      this.table.loading = true
      const response = await API.graphql(graphqlOperation(listInstruments));
      this.table.items = response.data.listInstruments.items;
      this.table.loading = false
    },
    async addInstrument(instrument) {
      this.table.loading = true
      this.table.items.push(instrument)
      this.table.loading = false
    },
    async updateInstrument(instrument) {
      try {
        instrument.state.enabled = !instrument.state.enabled
        await API.graphql(graphqlOperation(updateInstrument, { input: { id: instrument.id, state: instrument.state } } ));
        this.notify({ title: 'Success', text: 'Instrument was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch (error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Instrument failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async deleteInstrument(instrument, swalCallback) {
      try {
        this.table.loading = true
        await this.cascadeDeleteInstrument(instrument.id, swalCallback)
        this.table.items = this.table.items.filter(item => item.id !== instrument.id);
        this.notify({ title: 'Success', text: 'Instrument was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Instrument failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for Swal
      }
      finally {
        this.table.loading = false
      }
    },
    cascadeDeleteInstrument,

    /** Subscriptions **/
    onCreateInstrument() {
      this.table.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateInstrument)).subscribe((sourceData) => {
        this.syncNotification()
        const instrument = sourceData.value.data.onCreateInstrument
        if (instrument && !this.table.items.map(item => item.id).includes(instrument.id)) {
          this.table.loading = true
          this.table.items = [instrument, ...this.table.items];
          this.table.loading = false
        }
      });
    },
    onUpdateInstrument() {
      this.table.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateInstrument)).subscribe((sourceData) => {
        this.syncNotification()
        const instrument = sourceData.value.data.onUpdateInstrument
        if (instrument && this.table.items.map(item => item.id).includes(instrument.id)) {
          this.table.loading = true
          const index = this.table.items.findIndex(item => item.id === instrument.id)
          if(index > -1) {
            this.table.items.splice(index, 1, instrument)
          }
          this.table.loading = false
        }
      });
    },
    onDeleteInstrument() {
      this.table.subscriptions.onDelete = API.graphql(graphqlOperation(onDeleteInstrument)).subscribe((sourceData) => {
        this.syncNotification()
        const instrument = sourceData.value.data.onDeleteInstrument
        if(instrument && this.table.items.map(item => item.id).includes(instrument.id)) {
          this.table.loading = true
          this.table.items = this.table.items.filter(item => item.id !== instrument.id);
          this.table.loading = false
        }
      });
    },

    /** Util **/
    async refresh() {
      await this.listInstruments()
    },
  }
}
</script>

<style lang="scss">

</style>
