export const createInstrument = /* GraphQL */ `
    mutation CreateInstrument(
        $input: CreateInstrumentInput!
        $condition: ModelInstrumentConditionInput
    ) {
        createInstrument(input: $input, condition: $condition) {
            id
            slug
            name
            order
            form {
                id
                name
                state {
                    enabled
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            formInstrumentsId
        }
    }
`;

export const listForms = /* GraphQL */ `
    query ListForms(
        $id: ID
        $filter: ModelFormFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listForms(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const onUpdateForm = /* GraphQL */ `
    subscription OnUpdateForm {
        onUpdateForm {
            id
            name
            state {
                enabled
            }
        }
    }
`;

