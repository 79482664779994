<template>
  <b-modal :id="id" centered size="xl" title="New Instrument" no-close-on-backdrop footer-class="d-block" @hidden="clear()">
    <!-- Content -->
    <template #default>
      <b-container>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form ref="form" @submit.stop.prevent="handleSubmit(create)">
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="name" name="Name" rules="required">
                  <b-form-group label="Name" label-class="form-control-label" label-for="name-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="name-input" v-model="instrument.name" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Form -->
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="form" name="Form" rules="required">
                  <b-form-group label="Form" label-class="form-control-label" label-for="form-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <v-select id="form-input"
                              v-model="instrument.form"
                              :options="forms"
                              :loading="options.forms.loading"
                              :selectable="option => option.state.enabled === true"
                              label="name"
                              :reduce="val => val.id"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100">

                      <template #option="{ name, state }">
                        <div class="d-flex justify-content-between">
                          <span>{{ name }}</span>
                          <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                        </div>
                      </template>

                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="auto">
                <validation-provider v-slot="validationContext" vid="name" name="Order" rules="required|double">
                  <b-form-group label="Order" label-class="form-control-label" label-for="order-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="order-input" v-model="instrument.order" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-container>
    </template>

    <!-- Footer -->
    <template #modal-footer>
      <b-row>
        <b-col align-self="center">
          <b-checkbox v-model="state.redirect" :checked="true" :unchecked-value="false">
            <small>Redirect to page on submit</small>
          </b-checkbox>
        </b-col>
        <b-col align-self="center" class="text-right">
          <b-button type="reset" class="mr-1" @click="clear()">Clear</b-button>
          <b-button type="submit" variant="primary" @click="$refs.observer.handleSubmit(create)">Submit</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { uuid } from 'vue-uuid';
  import vSelect from 'vue-select'
  import notify from '@/mixins/notify.mixin'
  import {listForms, createInstrument, onUpdateForm} from '@/graphql/queries/instrument-modal';
  import slugify from 'slugify';
  import {onUpdateDistrict} from '@/graphql/queries/school-modal';

  export default {
    name: 'FormModal',
    components: {
      vSelect
    },
    mixins: [ notify ],
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        instrument: {
          name: null,
          form: null,
          order: 0
        },
        options: {
          forms: {
            items: [],
            loading: true,
            subscription: null
          }
        },
        state: {
          loading: true,
          redirect: false
        },
      }
    },
    computed: {
      forms() {
        if(this.options.forms.loading) {
          this.listForms()
          this.onUpdateForm()
          return []
        }
        return this.options.forms.items
      },
    },
    beforeDestroy() {
      if(this.options.forms?.subscription) { this.options.forms.subscription.unsubscribe() }
    },
    methods: {
      async create() {
        try {
          const id = uuid.v4();
          const slug = slugify(this.instrument.name, { lower: true })
          const instrumentInput = {
            id: id,
            slug: slug,
            name: this.instrument.name,
            order: this.instrument.order,
            formInstrumentsId: this.instrument.form,
            state: {
              enabled: true
            }
          }

          const response = await API.graphql(graphqlOperation(createInstrument, { input: instrumentInput }));
          const instrument = response.data.createInstrument

          if(this.state.redirect) {
            await this.$router.push({name: 'management-instrument', params: { id: id, slug: slug }})
          }
          else {
            this.$emit('created', instrument)
            this.$root.$emit('bv::toggle::modal', this.id)
            this.notify({ title: 'Success', text: 'Instrument was successfully created', icon: 'fas fa-server', variant: 'success' });
          }
        }
        catch (error) {
          console.error(error)
          this.notify({ title: 'Error', text: 'Instrument failed to create', icon: 'fas fa-server', variant: 'danger'});
        }
      },
      async listForms() {
        const response = await API.graphql(graphqlOperation(listForms));
        this.options.forms.items = response.data.listForms.items.sort((a, b) => a.name.localeCompare(b.name));
        this.options.forms.loading = false
      },
      async onUpdateForm() {
        this.options.forms.subscription = API.graphql(graphqlOperation(onUpdateForm)).subscribe((sourceData) => {
          this.syncNotification()
          const district = sourceData.value.data.onUpdateForm
          if (district && this.options.forms.items.map(item => item.id).includes(district.id)) {
            this.options.forms.loading = true
            const index = this.options.forms.items.findIndex(item => item.id === district.id)
            if(index > -1) {
              this.options.forms.items.splice(index, 1, district)
            }
            this.options.forms.loading = false
          }
        });
      },



      clear() {
        this.instrument = {
          name: null,
          instrument: null
        }
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@core/scss/vue/libs/vue-select.scss';
</style>
